// import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ERPLINK, RAZORPAYKEY, SITETITLE } from "../../../constants";

import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";
import axios from "axios";
import { isEmpty } from "lodash";

const ZoomCard1 = (props) => {
  const data = (props.data && props.data) || null;
  const plan = (props.plan && props.plan) || null;
  const countryDetails = (props.countryDetails && props.countryDetails) || null;
  const [currencySymbol, setCurrencySymbol] = useState("₹");
  useEffect(() => {
    if (!isEmpty(countryDetails)) {
      setCurrencySymbol(countryDetails.currencySymbol);
    }
  }, [countryDetails]);
  // Check if the string exists within the component
  const isEnterpriseExists = localStorage.getItem("authUserType")
    ? localStorage.getItem("authUserType").includes("enterprise", "employee")
    : null;

  const generateOrderId = () => {
    const prefix = "CIQ"; // You can set a prefix for your order ID
    const timestamp = Date.now(); // Get the current timestamp
    // const random = Math.floor(Math.random() * 10000); // Generate a random number (between 0 and 9999)

    // Concatenate the prefix, timestamp, and random number to form the order ID
    const orderId = `${prefix}${timestamp}`;

    return orderId;
  };

  const orderId = generateOrderId();
  const [Razorpay] = useRazorpay();

  const handleAddOnsPlan = useCallback(
    (items, planPriceType) => {
      const payAmt =
        planPriceType === "year"
          ? items.annuallyPrice * 100 * 12
          : items.monthlyPrice * 100;

      const options = {
        key: RAZORPAYKEY,
        amount: parseFloat(payAmt.toFixed(2)),
        currency: "INR",
        name: SITETITLE,
        description:
          "Order ID " +
          orderId +
          "Confirm and proceed securely with our streamlined payment gateway.",
        image:
          "https://www.cosmosiq.ai/static/media/logo.a259b674c493e27b3baa.png",
        receipt: orderId,
        handler: async (res) => {
          if (res.razorpay_payment_id) {
            const values = {
              addOnPlansId: items.addOnPlansId,
              title: items.title,
              planPriceType: planPriceType,
              monthlyPrice: items.monthlyPrice,
              annuallyPrice: items.annuallyPrice,
              description: items.description,
              payAmount: payAmt,
              orderId: orderId,
              transactionId: res.razorpay_payment_id,
            };
            await axios
              .post(
                window.apiBaseUrl + "auth/lms/razorpay/purchase-addons",
                values,
                {
                  headers: {
                    AccessToken: "Bearer " + localStorage.getItem("authUser"),
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  toast.success(res.data.message, {
                    autoClose: 5000,
                    theme: "colored",
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 3500);
                } else {
                  toast.warn(res.data.message, {
                    autoClose: 3000,
                    theme: "colored",
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 3500);
                }
              })
              .catch((err) => {
                // toast.error(err.response.data.message, {
                //   autoClose: 5000,
                //   theme: "colored",
                // });
              });
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "",
        },
        notes: {
          address: SITETITLE,
          order_id: orderId,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );

  return (
    <section className="mt-5">
      <div className="container">
        <div className="row row-cols-1 d-flex justify-content-center row-cols-md-3 g-4">
          {data.map((item, index) => (
            <div
              key={index + 1}
              style={{ width: "400px" }}
              className="col d-flex flex-wrap gap-2 shadow-lg p-3 m-2 bg-white rounded-2 mb-2 "
            >
              <div className={``}>
                <div className="d-flex align-items-start gap-2">
                  <img
                    src={item.icon}
                    alt={item.title}
                    style={{ height: "2.25rem", width: "2.25rem" }}
                  />
                  <div>
                    <h3 className="mb-0">{item.title}</h3>
                    <p style={{ letterSpacing: "1.3px" }}>
                      {currencySymbol}
                      {countryDetails &&
                      countryDetails.currencySymbol !== "₹" ? (
                        <>
                          {plan === "year"
                            ? (
                                parseInt(item.annuallyPrice) /
                                countryDetails.value
                              ).toFixed(2)
                            : (
                                parseInt(item.monthlyPrice) /
                                countryDetails.value
                              ).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {plan === "year"
                            ? item.annuallyPrice
                            : item.monthlyPrice}
                        </>
                      )}
                      /{/* {plan === "year" ? "year" : "month"}{" "} */}
                      month
                      {item.subTitle ? item.subTitle : ""}
                    </p>
                    <p className="pt-2" style={{ fontSize: "14px" }}>
                      {item.description ? item.description : ""}
                    </p>
                  </div>
                </div>
                <p className="my-3">{item.text}</p>
                <div className="d-flex gap-2 justify-content-end">
                  {item.buttonName && (
                    <a
                      href={item.buttonLink}
                      className="btn btn-outline-primary fw-normal"
                    >
                      {item.buttonName}
                    </a>
                  )}

                  {item.isLock === "lock" ? (
                    <button
                      className={`btn btn-primary btn-hover-success fw-normal text-$ `}
                    >
                      <i className="fa fa-lock"></i>
                      &nbsp; Let`s Begin
                    </button>
                  ) : (
                    <>
                      {isEnterpriseExists ? (
                        <>
                          {item.isActivePlan === false ? (
                            <span
                              onClick={() => handleAddOnsPlan(item, plan)}
                              className={`btn btn-primary btn-hover-success fw-normal`}
                            >
                              Let`s Begin
                            </span>
                          ) : (
                            <Link className="btn btn-danger w-50">
                              Activated
                            </Link>
                          )}
                        </>
                      ) : (
                        <Link
                          to={ERPLINK}
                          className={`btn btn-primary btn-hover-success fw-normal text-$ `}
                        >
                          &nbsp; Let`s Begin
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
// ZoomCard1.propTypes = {
//   data: PropTypes.array.isRequired,
// };

export default ZoomCard1;
