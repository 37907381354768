import React, { useCallback, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import { RAZORPAYKEY, SITETITLE } from "../constants";
import axios from "axios";
import { useQuery } from "react-query";
// import { useFormik } from "formik";
// import { forBilling } from "../config/formValidation";
// import { toast } from "react-toastify";
import Loading from "../components/global/Loading";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";

const getCountryData = async () => {
  const response = await axios.get(window.apiBaseUrl + "master/country");
  return response.data.data;
};

const initialValues = {
  countryId: "",
  stateId: "",
  fullName: "",
  pincode: "",
  address: "",
};

const Checkout = () => {
  const {
    data: country,
    // error,
    isLoading,
  } = useQuery("getCountry", getCountryData, { cacheTime: 0 });

  const navigate = useNavigate();

  const [profileData, setProfileData] = useState([]);
  // const [stateData, setStateData] = useState([]);
  const {
    isEmpty,
    totalItems,
    totalUniqueItems,
    items,
    cartTotal,
    inCart,
    removeItem,
    emptyCart,
  } = useCart();

  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [couponPrice, setCouponPrice] = useState("");
  const [totalOfferAmount, setTotalOfferAmount] = useState("");
  const [couponCode, setCouponCode] = useState("");

  // const [isAddress, setIsAddress] = useState(false);
  const [Razorpay] = useRazorpay();

  useEffect(() => {
    getProfileData();

    if (!isEmpty) {
      const couponData = items[0].couponData;
      if (couponData) {
        setIsDiscountApplied(true);
        setCouponPrice(couponData.couponPrice);
        setCouponCode(couponData.couponCode);
        setTotalOfferAmount(couponData.totalOfferAmount);
      } else {
        setIsDiscountApplied(false);
        setCouponPrice("");
        setCouponCode("");
        setTotalOfferAmount("");
      }
    }
  }, [isEmpty, items, removeItem]);

  const generateOrderId = () => {
    const prefix = "CIQ"; // You can set a prefix for your order ID
    const timestamp = Date.now(); // Get the current timestamp
    // const random = Math.floor(Math.random() * 10000); // Generate a random number (between 0 and 9999)

    // Concatenate the prefix, timestamp, and random number to form the order ID
    const orderId = `${prefix}${timestamp}`;

    return orderId;
  };

  const orderId = generateOrderId();

  const getProfileData = async () => {
    if (
      localStorage.getItem("authUser") &&
      localStorage.getItem("authUserType")
    ) {
      await axios
        .get(window.apiBaseUrl + "/auth/profile", {
          headers: {
            AccessToken:
              "Bearer " + JSON.parse(localStorage.getItem("authUser")),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setProfileData(res.data.data);
          }
        })
        .catch((err) => {
          // toast.error(err.response.data.message, {
          //   autoClose: 5000,
          //   theme: "colored",
          // });
        });
    }
  };

  const checkingCartItems = async () => {
    if (!isEmpty) {
      const checkingCartItems = {
        cartItem: items,
      };
      await axios
        .post(window.apiBaseUrl + "auth/cart/checking", checkingCartItems, {
          headers: {
            AccessToken:
              "Bearer " + JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data) {
              res.data.data.forEach((cartItems, index) => {
                if (inCart(cartItems.subjectId)) {
                  removeItem(cartItems.subjectId);
                }
              });
            }
          }
        })
        .catch((err) => {
          // toast.error(err.response.data.message, {
          //   autoClose: 5000,
          //   theme: "colored",
          // });
        });
    }
  };

  useEffect(() => {
    if (!isEmpty) {
      checkingCartItems();
    }
    if (
      profileData.countryId &&
      profileData.stateId &&
      profileData.fullName &&
      profileData.pincode &&
      profileData.address
    ) {
      // setIsAddress(true);
    }
  }, [isEmpty, profileData]);

  initialValues.countryId = profileData.countryId ? profileData.countryId : "";
  initialValues.stateId = profileData.stateId ? profileData.stateId : "";
  initialValues.fullName = profileData.fullName ? profileData.fullName : "";
  initialValues.pincode = profileData.pincode ? profileData.pincode : "";
  initialValues.address = profileData.address ? profileData.address : "";

  // const handleCountryChange = (value) => {
  //   setFieldValue("countryId", value);
  //   getStateData(value);
  // };

  // const getStateData = async (values) => {
  //   await axios
  //     .get(window.apiBaseUrl + "/master/state", {
  //       params: { countryId: values },
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setStateData(res.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       // toast.error(err.response.data.message, {
  //       //   autoClose: 5000,
  //       //   theme: "colored",
  //       // });
  //     });
  // };

  // const handleStateChange = (value) => {
  //   setFieldValue("stateId", value);
  // };

  // const handleNameChange = (value) => {
  //   setFieldValue("fullName", value);
  // };

  // const handlePincodeChange = (value) => {
  //   setFieldValue("pincode", value);
  // };

  // const handleAddressChange = (value) => {
  //   setFieldValue("address", value);
  // };

  // const [getOrderData, setOrderData] = useState([]);

  // useEffect(() => {
  //   const values = {
  //     orderId: orderId,
  //     cartItem: items
  //   };
  //   setOrderData(values);
  // },[orderId]);

  const orderData = {
    orderId: orderId,
    cartItem: items,
  };

  const payAmt =
    totalOfferAmount && totalOfferAmount
      ? totalOfferAmount * 100
      : cartTotal * 100;

  const handleMakePayment = useCallback(
    (payAmt) => {
      const options = {
        key: RAZORPAYKEY,
        amount: parseFloat(payAmt.toFixed(2)),
        currency: "INR",
        name: SITETITLE,
        description:
          "Order ID " +
          orderId +
          " Confirm and proceed securely with our streamlined payment gateway.",
        image:
          "https://www.cosmosiq.ai/static/media/logo.a259b674c493e27b3baa.png",
        receipt: orderId,
        handler: async (res) => {
          if (res.razorpay_payment_id) {
            const updatedValues = {
              ...orderData,
              payAmt: payAmt,
              razorpay_payment_id: res.razorpay_payment_id,
            };

            await axios
              .post(window.apiBaseUrl + "auth/razorpay/order", updatedValues, {
                headers: {
                  AccessToken:
                    "Bearer " + JSON.parse(localStorage.getItem("authUser")),
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                if (res.status === 201) {
                  emptyCart();
                  toast.success(res.data.message, {
                    autoClose: 5000,
                    theme: "colored",
                  });
                  navigate(
                    `/payment-successful?orderId=${res.data.purchasedData.orderId}`,
                    {
                      state: { data: res.data.purchasedData },
                    }
                  );
                } else {
                  toast.warn(res.data.message, {
                    autoClose: 3000,
                    theme: "colored",
                  });
                }
              })
              .catch((err) => {
                // toast.error(err.response.data.message, {
                //   autoClose: 5000,
                //   theme: "colored",
                // });
              });
          }
        },
        prefill: {
          name: profileData.fullName ? profileData.fullName : "",
          email: profileData.emailId ? profileData.emailId : "",
          contact: profileData.mobileNumber ? profileData.mobileNumber : "",
        },
        notes: {
          address: SITETITLE,
          order_id: orderId,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );

  // const {
  //   values,
  //   errors,
  //   dirty,
  //   isValid,
  //   isSubmitting,
  //   touched,
  //   handleBlur,
  //   setFieldValue,
  //   handleSubmit,
  // } = useFormik({
  //   initialValues: initialValues,
  //   validationSchema: forBilling,
  //   onSubmit: async (values, action) => {
  //     await axios
  //       .put(window.apiBaseUrl + "/auth/profile", values, {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           AccessToken:
  //             "Bearer " + JSON.parse(localStorage.getItem("authUser")),
  //         },
  //       })
  //       .then((res) => {
  //         if (res.status === 200) {
  //           toast.success(res.data.message, {
  //             autoClose: 5000,
  //             theme: "colored",
  //           });
  //           window.location.reload();
  //         } else {
  //           toast.warn(res.data.message, {
  //             autoClose: 5000,
  //             theme: "colored",
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(err.response.data.message, {
  //           autoClose: 5000,
  //           theme: "colored",
  //         });
  //       });
  //   },
  // });

  useEffect(() => {
    if (inCart) {
      const cartItems = {
        items: items,
        isEmpty: isEmpty,
        totalItems: totalItems,
        totalUniqueItems: totalUniqueItems,
        cartTotal: cartTotal,
      };
      const iframe = document.querySelector("iframe");
      const wind = iframe.contentWindow;
      const data = { cartItems };
      wind.postMessage(data, "*");
    }
  }, [inCart]);

  if (isLoading) return <Loading />;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <div
        className="section page-banner-section bg-color-1 checkout-banner"
        style={{
          backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/checkout-banner.png"
          )`,
        }}
      >
        <div className="container">
          <div className="course-details-banner-content">
            <h2 className="title">
              <p>
                Cosmos IQ is chosen by top organizations and institutions to
                help them develop in-demand career skills.
              </p>
              <br />
              Your Cart
            </h2>
          </div>
        </div>
      </div>
      {isEmpty ? (
        <>
          <section className="cart-empty">
            <div className="container h-50">
              <div className="cart-empty-inner">
                <h5>Your cart is empty</h5>
                <Link to={"/filter"} className="cont_shop">
                  Explore our courses
                </Link>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="checkout-section mt-2">
            <div className="container">
              <div className="row">
                {/* <div className="col-md-7">
                  <form
                    id="userCheckoutStore"
                    autoComplete="off"
                    noValidate="novalidate"
                    onSubmit={handleSubmit}
                  >
                    <div className="checkout-outer">
                      <h3>Billing Information</h3>
                      <div className="address-section-main">
                        {isAddress ? (
                          <div className="address-section">
                            <div className="address-content">
                              <h6>
                                {profileData.fullName
                                  ? profileData.fullName
                                  : ""}
                                &nbsp;
                                {profileData.mobileNumber
                                  ? profileData.mobileNumber
                                  : ""}
                              </h6>
                              <p>
                                {profileData.address ? profileData.address : ""}
                                ,{" "}
                                {profileData.pincode ? profileData.pincode : ""}
                              </p>
                            </div>
                            <div className="address-edit">
                              <i className="fa fa-check bg-success"></i>
                              <Link
                                to={STUDENTLINK + "/edit-profile"}
                                className="edit-pen"
                              >
                                Edit
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div className="address-form">
                            <p>
                              We use this information to register products,
                              secure your identity and calculate taxes and fees.
                            </p>
                            <p className="field-text">
                              All feilds are required.
                            </p>
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <div className="form-group">
                                  {Array.isArray(country) ? (
                                    <select
                                      name="countryId"
                                      className="form-control"
                                      value={values.countryId}
                                      onChange={(e) => {
                                        handleCountryChange(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                    >
                                      <option defaultValue="">
                                        Select Country...
                                      </option>
                                      {country.map((country, index) => (
                                        <option
                                          key={index}
                                          value={country.countryId}
                                        >
                                          {country.countryName}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    ""
                                  )}
                                  {errors.countryId && touched.countryId ? (
                                    <p className="text-danger">
                                      {errors.countryId}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="form-group">
                                  {Array.isArray(stateData) ? (
                                    <select
                                      name="stateId"
                                      id="stateId"
                                      className="form-control"
                                      value={values.stateId}
                                      onChange={(e) => {
                                        handleStateChange(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                    >
                                      <option defaultValue="">
                                        --Select State--
                                      </option>
                                      {stateData.map((state, index) => (
                                        <option
                                          key={index}
                                          value={state.stateId}
                                        >
                                          {state.stateName}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    ""
                                  )}
                                  {errors.stateId && touched.stateId ? (
                                    <p className="text-danger">
                                      {errors.stateId}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <input
                                  type="text"
                                  name="fullName"
                                  maxLength="15"
                                  id="fullName"
                                  placeholder="Full name"
                                  className="form-control isAlpha"
                                  value={values.fullName}
                                  onChange={(e) =>
                                    handleNameChange(e.target.value)
                                  }
                                  onBlur={handleBlur}
                                />
                                {errors.fullName && touched.fullName ? (
                                  <p className="text-danger">
                                    {errors.fullName}
                                  </p>
                                ) : null}
                              </div>
                              <div className="col-md-6 mb-3">
                                <input
                                  type="text"
                                  name="pincode"
                                  id="pincode"
                                  placeholder="Pin Code*"
                                  maxLength="6"
                                  minLength="6"
                                  className="isInteger form-control"
                                  value={values.pincode}
                                  onChange={(e) =>
                                    handlePincodeChange(e.target.value)
                                  }
                                  onBlur={handleBlur}
                                />
                                {errors.pincode && touched.pincode ? (
                                  <p className="text-danger">
                                    {errors.pincode}
                                  </p>
                                ) : null}
                              </div>
                              <div className="col-md-12 mb-3">
                                <input
                                  type="text"
                                  name="address"
                                  id="address"
                                  placeholder="Address"
                                  className="form-control isAlpha"
                                  value={values.address}
                                  onChange={(e) =>
                                    handleAddressChange(e.target.value)
                                  }
                                  onBlur={handleBlur}
                                />
                                {errors.address && touched.address ? (
                                  <p className="text-danger">
                                    {errors.address}
                                  </p>
                                ) : null}
                              </div>
                              <div className="col-md-6 mb-3">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-hover-heading-color submit"
                                  disabled={
                                    !(dirty && isValid && !isSubmitting)
                                  }
                                >
                                  {isSubmitting ? "Please wait..." : "Submit"}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>                      
                    </div>
                  </form>
                  {isAddress ? (
                        <div className="payment-section">
                          <h3>Payment Method</h3>
                          <hr />
                          <div className="method-list1">
                            <div className="comment-form-consent input-box">
                              <input
                                id="radio-1"
                                type="radio"
                                name="selector"
                                defaultValue="Paytm"
                                defaultChecked=""
                              />
                              <label htmlFor="radio-1">
                                <img
                                  src="https://www.matriyeacademy.com/website-assets/images/paytm-icon.svg"
                                  alt=""
                                  className="paytm-logo"
                                />
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <button type="submit" className="save-btn submit" onClick={handleMakePayment}>
                                Make Payment
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                </div> */}

                <div className="col-md-5 mt-5 mb-5">
                  <div className="checkout-summary">
                    <h3>Order Summary</h3>
                    <div className="checkout-item">
                      <p>{totalUniqueItems} Item</p>
                      <Link to={"/cart"}>Edit</Link>
                    </div>
                    <hr />
                    <div className="disclamers">
                      <div className="checkout-subtotal">
                        <h6>Subtotal</h6>
                        <p>
                          <i className="fa fa-inr text-dark"></i>&nbsp;
                          {cartTotal}
                        </p>
                      </div>
                      {isDiscountApplied ? (
                        <>
                          <div className="subtotal-price">
                            <h4>Discounted Coupon</h4>
                            <p>
                              <i className="fa fa-inr text-dark"></i>&nbsp;
                              <span id="discountedCouponPrice">
                                {couponPrice}
                              </span>
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="checkout-gst">
                        <h6>Included GST 18% </h6>
                      </div>
                      {isDiscountApplied ? (
                        <>
                          <div className="checkout-promo">
                            <h6>
                              Promo Code <span>-</span> <b>{couponCode}</b>
                            </h6>
                          </div>
                          <p className="valid-promo">
                            <i className="fa fa-check"></i>{" "}
                            <b>Valid promo code</b> You're getting the best
                            price we have got.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      <hr />
                      <div className="checkout-total">
                        <h6>Total (INR)</h6>
                        <p>
                          <i className="fa fa-inr text-dark"></i>&nbsp;
                          {totalOfferAmount ? totalOfferAmount : cartTotal}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Link
                    onClick={() => handleMakePayment(payAmt)}
                    className="ready-btn text-white"
                  >
                    Continue to pay
                  </Link>
                </div>

                <div className="col-md-7 mt-5 mb-5">
                  <Accordion defaultActiveKey="0" activeKey={0}>
                    <Accordion.Item eventKey={0}>
                      <Accordion.Header>
                        Your Items ({totalUniqueItems})
                      </Accordion.Header>
                      <Accordion.Body>
                        {items.map((item, index) => (
                          <div
                            key={index}
                            className="checkout-items-list-content"
                          >
                            <div className="checkout-items-list-content-left">
                              <h6>{item.subjectName}</h6>
                              <p>{item.courseName}</p>
                              <p>
                                <Link
                                  variant="danger"
                                  onClick={() => removeItem(item.id)}
                                  className="text-danger"
                                >
                                  <i className="fa fa-trash close-summary text-danger"></i>
                                </Link>
                              </p>
                            </div>
                            <div className="summary-right">
                              <h5 className="summary-price">
                                <i className="fa fa-inr"></i>&nbsp;{item.price}
                              </h5>
                            </div>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Checkout;
