import React, { useState } from "react";
import List from "../components/core/course-list/List";
import Grid from "../components/core/course-list/Grid";
import { Link, useNavigate } from "react-router-dom";
import { SITETITLE } from "../constants";
import { useQuery } from "react-query";
import axios from "axios";
import { Helmet } from "react-helmet";
import Pagination from "./pagination";
import NoRecordFound from "../components/core/no-record-found/NoRecordFound";
import Loading from "../components/global/Loading";

const Filter = () => {
  const [getCategoryId, setCategoryId] = useState("");
  const [getDepartmentId, setDepartmentId] = useState("");
  const [getCourseId, setCourseId] = useState("");
  const [getLevel, setLevel] = useState("");
  const [getType, setType] = useState("");

  const [selectedView, setSelectedView] = useState("list");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(24);
  let offset = 0;

  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search); //getdata for edit blog
  const querySearch = searchParams.get("q");

  const handleViewChange = (view) => {
    setSelectedView(view);
    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setCategoryId("");
    setDepartmentId("");
    setCourseId("");
    setLevel("");
    setType("");
    setCurrentPage(1);
    navigate("/filter");
    window.location.reload();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "category") {
      setCategoryId(value);
      setDepartmentId("");
      setCourseId("");
      setLevel("");
      setType("");
    } else if (name === "department") {
      setDepartmentId(value);
      setCourseId("");
      setLevel("");
      setType("");
    } else if (name === "course") {
      setCourseId(value);
      setLevel("");
      setType("");
    } else if (name === "level") {
      setLevel(value);
      setType("");
    } else if (name === "price-type") {
      setType(value);
    }
    setCurrentPage(1);
  };

  offset = (currentPage - 1) * itemsPerPage;

  let params = {
    category: getCategoryId,
    department: getDepartmentId,
    course: getCourseId,
    level: getLevel,
    priceType: getType,
    searchTerm: querySearch,
    limit: itemsPerPage,
    offset: offset,
    //sort:newestfirst
  };

  const getFilterData = async (params) => {
    const response = await axios.get(
      window.apiBaseUrl + "subject/subject-filter",
      { params }
    );
    return response.data.data;
  };

  const {
    data: filter,
    isLoading: filterLoading,
    isSuccess,
  } = useQuery(
    [
      "filterData",
      getCategoryId,
      getDepartmentId,
      getCourseId,
      getLevel,
      getType,
      offset,
      querySearch,
    ],
    () => getFilterData(params),
    {
      staleTime: 30000, // 30 seconds
      enabled: true, // whether the query should be fetched on component mount
      refetchOnMount: false, // whether the query should be refetched on component mount
      refetchOnWindowFocus: true, // whether the query should be refetched when window regains focus
      refetchInterval: 60000, // interval in milliseconds to automatically refetch data
      refetchIntervalInBackground: true, // whether to refetch during background tabs
      retry: 3, // number of retries before considering a query as a failure
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // delay between retries
      cacheTime: 600000, // time in milliseconds to keep data in cache
      onSuccess: () => "", // callback function on successful fetch
      onError: (error) => console.error("Error fetching data:", error), // callback function on fetch error
    }
  );

  const getCategoryData = async () => {
    const response = await axios.get(window.apiBaseUrl + "master/category");
    return response.data.data;
  };

  const { data: categoryData, isLoading: categoryLoading } = useQuery(
    "categoryData",
    getCategoryData,
    { cacheTime: 0 }
  );

  const filterData = filter || {};
  const totalPages = Math.ceil(filterData.totalRecords / itemsPerPage);

  if (filterLoading || categoryLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{SITETITLE} :: Courses list</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <div
        className="section page-banner-section bg-color-1 course-list-banner"
        style={{
          backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/bg-banner.png"
          )`,
        }}
      >
        <div className="container">
          <div className="page-banner-content">
            <h1 className="title">Courses to get you started</h1>
          </div>
        </div>
      </div>
      <div className="section section-padding">
        <div className="container">
          <div className="course-list-wrapper">
            <div className="row flex-row-reverse">
              <div className="col-lg-9">
                <div className="course-top-bar">
                  <div className="course-top-text">
                    <p>
                      We found{" "}
                      <span>
                        {filterData.totalRecords
                          ? filterData.totalRecords
                          : "0"}
                      </span>{" "}
                      Courses For You
                    </p>
                  </div>
                  <div className="course-top-inner">
                    <ul className="course-top-menu">
                      <Link
                        onClick={handleResetFilter}
                        className="btn-link pe-4 text-danger label fw-semibold"
                      >
                        Clear Filter
                      </Link>
                      <span className="label">View</span>
                      <ul className="nav">
                        <li>
                          <Link onClick={() => handleViewChange("grid")}>
                            <i
                              className={`fa fa-th-large ${
                                selectedView === "grid"
                                  ? "text-primary"
                                  : "text-secondary"
                              }`}
                            ></i>
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link onClick={() => handleViewChange("list")}>
                            <i
                              className={`fa fa-th-list ${
                                selectedView === "list"
                                  ? "text-primary"
                                  : "text-secondary"
                              }`}
                            ></i>
                          </Link>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="list">
                    {/* {isLoading === true ? <div>Fetching posts...</div> : ""} */}
                    {isSuccess === false ? (
                      <NoRecordFound
                        link="/filter"
                        buttonName="Explore our Courses"
                      />
                    ) : (
                      ""
                    )}
                    {selectedView === "list" ? (
                      <List data={filterData.subjectList} />
                    ) : (
                      <Grid data={filterData.subjectList} />
                    )}
                  </div>
                </div>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </div>
              <div className="col-lg-3">
                <div className="sidebar-wrap-02">
                  <form className="filter">
                    {Array.isArray(categoryData) ? (
                      <div className="sidebar-widget-02">
                        <h3 className="widget-title">Categories</h3>
                        <div className="widget-checkbox">
                          {Array.isArray(categoryData) ? (
                            <ul className="checkbox-list">
                              {categoryData.map((category, index) => (
                                <li key={index} className="form-check">
                                  <input
                                    className="form-check-input categoryId"
                                    type="radio"
                                    name="category"
                                    defaultValue={category.categoryId}
                                    id={category.categoryId}
                                    onChange={handleChange}
                                    checked={
                                      getCategoryId === category.categoryId
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={category.categoryId}
                                  >
                                    {category.categoryName}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {filterData.departmentList &&
                    filterData.departmentList.length ? (
                      <div className="sidebar-widget-02">
                        <h3 className="widget-title">Department</h3>
                        <div className="widget-checkbox">
                          {Array.isArray(filterData.departmentList) ? (
                            <select
                              className="py-2 px-1"
                              style={{ fontSize: "14px" }}
                              id="department"
                              name="department"
                              onChange={handleChange}
                            >
                              <option value="">All Departments</option>
                              {filterData.departmentList.map(
                                (department, index) => (
                                  <option
                                    key={index}
                                    value={department.departmentId}
                                    selected={
                                      getDepartmentId ===
                                      department.departmentId
                                    }
                                  >
                                    {department.departmentName}
                                  </option>
                                )
                              )}
                            </select>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {filterData.courseList && filterData.courseList.length ? (
                      <div className="sidebar-widget-02">
                        <h3 className="widget-title">Course</h3>
                        <div className="widget-checkbox">
                          {Array.isArray(filterData.courseList) ? (
                            <select
                              className="py-2 px-1"
                              style={{ fontSize: "14px" }}
                              id="course"
                              name="course"
                              onChange={handleChange}
                            >
                              {filterData.courseList.map((course, index) => (
                                <option
                                  key={index}
                                  value={course.courseId}
                                  selected={getCourseId === course.courseId}
                                >
                                  {course.courseName}
                                </option>
                              ))}
                            </select>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="sidebar-widget-02">
                      <h3 className="widget-title">Level</h3>

                      <div className="widget-checkbox">
                        <ul className="checkbox-list">
                          <li className="form-check">
                            <input
                              className="form-check-input level"
                              type="radio"
                              defaultValue="beginner"
                              id="beginner"
                              name="level"
                              onChange={handleChange}
                              checked={getLevel === "beginner"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="beginner"
                            >
                              Beginner
                            </label>
                          </li>
                          <li className="form-check">
                            <input
                              className="form-check-input level"
                              type="radio"
                              defaultValue="intermediate"
                              id="intermediate"
                              name="level"
                              onChange={handleChange}
                              checked={getLevel === "intermediate"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="intermediate"
                            >
                              Intermediate
                            </label>
                          </li>
                          <li className="form-check">
                            <input
                              className="form-check-input level"
                              type="radio"
                              defaultValue="advanced"
                              id="advanced"
                              name="level"
                              onChange={handleChange}
                              checked={getLevel === "advanced"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="advanced"
                            >
                              Advanced
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="sidebar-widget-02">
                      <h3 className="widget-title">Type of Courses</h3>
                      <div className="widget-checkbox">
                        <ul className="checkbox-list">
                          <li className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              defaultValue="free"
                              id="free"
                              name="price-type"
                              onChange={handleChange}
                              checked={getType === "free"}
                            />
                            <label className="form-check-label" htmlFor="free">
                              Free
                            </label>
                          </li>
                          <li className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              defaultValue="paid"
                              id="paid"
                              name="price-type"
                              onChange={handleChange}
                              checked={getType === "paid"}
                            />
                            <label className="form-check-label" htmlFor="paid">
                              Paid
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <Link
                      onClick={handleResetFilter}
                      className="btn btn-secondary btn-block"
                    >
                      Clear Filter
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Filter;
