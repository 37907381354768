import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { Col, NavItem, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ERPLINK, STUDENTLINK } from "../../../constants";
const PaymentSuccessful = () => {
  const location = useLocation();
  const data = location.state?.data;

  const navigate = useNavigate();

  if (
    isEmpty(localStorage.getItem("authUser")) &&
    isEmpty(localStorage.getItem("authUserType"))
  ) {
    navigate("/");
  }

  useEffect(() => {
    if (isEmpty(location.state)) {
      navigate("/");
    } else {
      window.dataLayer.push({
        event: "purchase",
        value: data.grantAmt,
        currency: "inr",
        transaction_id: `${data.orderId}`,
      });
    }
  }, [location]);

  return (
    <>
      {/* <div
        className="section page-banner-section bg-color-1 checkout-banner"
        style={{
          backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/checkout-banner.png"
          )`,
        }}
      >
        <div className="container">
          <div className="course-details-banner-content">
            <h2 className="title">
              <p>
                Cosmos IQ is chosen by top organizations and institutions to
                help them develop in-demand career skills.
              </p>
              <br />
              Thank you for choosing our product
            </h2>
          </div>
        </div>
      </div> */}

      <section>
        <Row className="py-4 d-flex justify-content-center">
          <Col lg={8} style={{ backgroundColor: "#f2f2f2" }}>
            <div className="text-center p-3">
              <lord-icon
                src="https://cdn.lordicon.com/gqjpawbc.json"
                trigger="loop"
                style={{ width: "120px", height: "120px" }}
              ></lord-icon>
              <h5 className="pt-4">You're all set</h5>
              <h6 className="pt-3 mb-5">Thank you for choosing our product</h6>

              <Row>
                <Col lg={6} className="px-5">
                  <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textWrap: "nowrap",
                        color: "#08a88a",
                      }}
                    >
                      Transaction ID
                    </span>
                    <h6 className="p-0">
                      {(data && data.transactionId) || "-"}
                    </h6>
                  </div>
                  {data && data.subjectSKUCode ? (
                    <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                      <span
                        style={{
                          fontSize: ".8rem",
                          textWrap: "nowrap",
                          color: "#08a88a",
                        }}
                      >
                        Product ID
                      </span>
                      <h6 className="p-0">
                        {(data && data.subjectSKUCode) || "-"}
                      </h6>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textWrap: "nowrap",
                        color: "#08a88a",
                      }}
                    >
                      Price
                    </span>
                    <h6 className="p-0">{(data && data.grantAmt) || "-"}</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textWrap: "nowrap",
                        color: "#08a88a",
                      }}
                    >
                      GST
                    </span>
                    <h6 className="p-0">{(data && data.gst) || "-"}</h6>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textWrap: "nowrap",
                        color: "#08a88a",
                      }}
                    >
                      State
                    </span>
                    <h6 className="p-0">Madhya Pradesh</h6>
                  </div> */}
                </Col>
                <Col lg={6} className="px-5">
                  <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textWrap: "nowrap",
                        color: "#08a88a",
                      }}
                    >
                      Order ID
                    </span>
                    <h6 className="p-0">{(data && data.orderId) || "-"}</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textWrap: "nowrap",
                        color: "#08a88a",
                      }}
                    >
                      Product Name
                    </span>
                    <h6 className="p-0">{(data && data.productName) || "-"}</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textWrap: "nowrap",
                        color: "#08a88a",
                      }}
                    >
                      Date
                    </span>
                    <h6 className="p-0">{(data && data.created_at) || "-"}</h6>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textWrap: "nowrap",
                        color: "#08a88a",
                      }}
                    >
                      Payment Mode
                    </span>
                    <h6 className="p-0">{(data && data.paymentmode) || "-"}</h6>
                  </div> */}
                  {/* <div className="d-flex align-items-center justify-content-between gap-4 mb-3">
                    <span
                      style={{
                        fontSize: ".8rem",
                        textWrap: "nowrap",
                        color: "#08a88a",
                      }}
                    >
                      Country
                    </span>
                    <h6 className="p-0">India</h6>
                  </div> */}
                </Col>
              </Row>

              {data && data.userType === "student" ? (
                <Link
                  to={STUDENTLINK}
                  className="btn btn-success mt-4"
                  style={{
                    padding: "0 5px",
                    fontSize: ".9rem",
                    fontWeight: "inherit",
                  }}
                >
                  Go to Dashboard
                </Link>
              ) : (
                <Link
                  to={ERPLINK}
                  className="btn btn-success mt-4"
                  style={{
                    padding: "0 5px",
                    fontSize: ".9rem",
                    fontWeight: "inherit",
                  }}
                >
                  Go to Dashboard
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default PaymentSuccessful;
