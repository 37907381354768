import { Container, Row, Col } from "react-bootstrap";
// import data from "./Data/ButtonData.json";
import Card from "./Cards/Card";
import CompareFeature from "./CompareFeature";
import ZoomOneExpirence from "./ZoomOneExpirence";
import Banner from "./Banner";
import Stories from "./Stories";
import Faqs from "./Faqs";
import Switch from "./Cards/Switch";
// import Atlassian from "./Atlassian";
import { useRef, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
// import Select from "react-select";
import SimpleBar from "simplebar-react";

import FeatureSectionComponent from "../../components/page-wise-components/home/FeatureSectionComponent";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const getFeaturedInData = async () => {
  const response = await axios.get(window.apiBaseUrl + "home/featured-in");
  return response.data.data;
};
const getCountryData = async () => {
  const response = await axios.get(window.apiBaseUrl + "master/country");
  return response.data.data;
};
const SubComponent = (props) => {
  const { staticDetails, planPricing, addOnsPlan, faqList } = props;

  const { data: featuredInData } = useQuery("featuredIn", getFeaturedInData, {
    cacheTime: 0,
  });
  const { data: countryData } = useQuery("countryData", getCountryData, {
    cacheTime: 0,
  });

  const myTargetComponentRef = useRef(null);

  const [switchButton, setSwitchButton] = useState("month");

  const toggleSwitch = () => {
    // Toggle between "month" and "year"
    setSwitchButton(switchButton === "month" ? "year" : "month");
  };

  const [countryDetails, setCountryDetails] = useState(null);

  // if (error) return <div>An error occurred: {error.message}</div>;

  // Filter out objects where currencySymbol and currencyValue are not null
  // const filteredData =
  //   countryData &&
  //   countryData.filter(
  //     (item) => item.currencySymbol !== null && item.currencyValue > 0
  //   );

  // const indianFlag =
  //   countryData && countryData.find((item) => item.countryId === "101");

  // const options =
  //   filteredData &&
  //   filteredData.map((country) => ({
  //     currencySymbol: country.currencySymbol,
  //     flagImg: country.flagImg,
  //     value: country.currencyValue,
  //     label: `${country.countryName} ${
  //       country.currencySymbol ? "(" + country.currencySymbol + ")" : ""
  //     }`,
  //   }));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredCountries =
    countryData &&
    countryData.filter((item) =>
      item.countryName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [countryflagImage, setCountryflagImage] = useState(
    "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png?20230723002237"
  );
  const handleSelectedCountry = (item) => {
    setSearchTerm(item.countryName);
    setCountryflagImage(item.flagImg);
    let data = {
      currencySymbol: item.currencySymbol,
      flagImg: item.flagImg,
      value: item.currencyValue,
      label: `${item.countryName} ${
        item.currencySymbol ? "(" + item.currencySymbol + ")" : ""
      }`,
    };
    setCountryDetails(data);
  };

  // const defaultValue = "India";
  // const [defaultCountry, setDefaultCountry] = useState("India");

  // const handleSelectChange = (selectedOption) => {
  //   setCountryDetails(selectedOption);
  // };

  return (
    <section className="subCompnent bg-white rounded-top-5">
      <Container fluid>
        {planPricing && planPricing ? (
          <>
            <Row>
              <Col className="col-md-5 col-12 mt-2 mx-4 ">
                <div
                  className="monthly d-flex flex-wrap gap-2 align-items-center"
                  onClick={toggleSwitch}
                >
                  <span>Monthly</span>
                  <Switch />
                  <span>Anually</span>
                  <p className="bg-success text-white px-2 rounded-4">
                    {(staticDetails &&
                      staticDetails.planPricingDiscountLabel) ||
                      "N/A"}
                  </p>
                </div>
              </Col>
              <Col className="col-12 col-md-6  d-flex align-items-center justify-content-end pe-5">
                <div className="country d-flex gap-3 align-items-center w-25">
                  {/* <Select
                    className="bg-transparent border-0 text-dark mt-2"
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "200px",
                        border: "1px solid #000",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontWeight: "500",
                        fontSize: "14px",
                        cursor: "pointer",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#000000",
                      }),
                    }}
                    options={
                      options &&
                      options.map((option) => ({
                        ...option,
                        label: (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={option.flagImg}
                              alt="Flag"
                              style={{ width: "20px", marginRight: "5px" }}
                            />
                            {option.label}
                          </div>
                        ),
                      }))
                    }
                    placeholder={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={(indianFlag && indianFlag.flagImg) || ""}
                          alt="Flag"
                          style={{ width: "20px", marginRight: "5px" }}
                        />
                        India (₹)
                      </div>
                    }
                  /> */}
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="mt-2"
                  >
                    <DropdownToggle
                      tag="div"
                      type="text"
                      className="position-relative"
                    >
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                            style={{ borderRadius: "0px" }}
                          >
                            <img
                              src={countryflagImage}
                              alt="Flag"
                              width="25"
                              height="25"
                            />
                          </span>
                        </div>
                        <input
                          autoComplete="off"
                          type="text"
                          id="countryName"
                          className="form-control mb-2"
                          style={{ height: "38.5px" }}
                          placeholder="India (₹)"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>

                      <i
                        className="ri-search-line position-absolute text-secondary"
                        style={{ right: "8px", top: "5px" }}
                      ></i>
                    </DropdownToggle>
                    <DropdownMenu
                      as="ul"
                      className="list-unstyled w-100 dropdown-menu-list mb-0"
                      onClick={() => setDropdownOpen(false)}
                    >
                      <SimpleBar
                        style={{ maxHeight: "220px", overflow: "auto" }}
                        className="bg-light"
                      >
                        {filteredCountries &&
                          filteredCountries.map((item, key) => (
                            <DropdownItem
                              as="li"
                              onClick={() => {
                                // setSelectedCountry(item); // Update selectedCountry state
                                handleSelectedCountry(item); // Call another function
                                // Close dropdown after selection
                              }}
                              key={key}
                              className="dropdown-item d-flex px-0"
                            >
                              <div className="flex-shrink-0 me-0">
                                <img
                                  src={item.flagImg}
                                  alt="country flag"
                                  className="options-flagimg"
                                  height="18"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <div className="d-flex">
                                  <div className="country-name me-1">
                                    {item.countryName}
                                  </div>
                                  <span className="countrylist-codeno text-muted">
                                    ({item.currencySymbol})
                                  </span>
                                </div>
                              </div>
                            </DropdownItem>
                          ))}
                      </SimpleBar>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Card
              countryDetails={countryDetails}
              plan={switchButton}
              data={planPricing ? planPricing.list : null}
              activePlanDetails={
                planPricing ? planPricing.activePlanDetails : null
              }
            />
          </>
        ) : (
          ""
        )}
        {addOnsPlan ? (
          <Row className="mt-4">
            <Col className="text-center">
              <button
                className="btn btn-primary btn-hover-success fs-5 "
                onClick={() =>
                  myTargetComponentRef.current?.scrollIntoView({
                    behavior: "smooth",
                  })
                }
              >
                Views Add-ons <i className="ri-arrow-down-line fs-5"></i>
              </button>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Container>
      <CompareFeature
        planPricing={planPricing}
        plan={switchButton}
        countryDetails={countryDetails}
      />
      <div ref={myTargetComponentRef}></div>
      {addOnsPlan ? (
        <ZoomOneExpirence
          countryDetails={countryDetails}
          staticDetails={staticDetails}
          addOnsPlan={addOnsPlan}
        />
      ) : (
        ""
      )}
      {staticDetails ? (
        staticDetails.sectionOneHeading ? (
          <Banner staticDetails={staticDetails} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {staticDetails ? (
        staticDetails.sectionTwoNameAndDesignation ? (
          <Stories staticDetails={staticDetails} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {featuredInData ? (
        <FeatureSectionComponent
          data={featuredInData.featuredInHeading}
          dataList={featuredInData.featureList}
        />
      ) : (
        ""
      )}
      {/* <Atlassian /> */}
      {faqList ? <Faqs faqList={faqList} /> : ""}
    </section>
  );
};

export default SubComponent;
