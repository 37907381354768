import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Subcomponent from "./SubComponent";
import "./App.css";
import { SITETITLE } from "../../constants";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import Loading from "../../components/global/Loading";

let headers = {};
// Check if authUser exists in local storage
const authUser = localStorage.getItem("authUser");
if (authUser) {
  headers.AccessToken = "Bearer " + authUser;
}

const getStaticDetails = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "plan-pricing/static-details"
  );
  return response.data.data;
};

const getButtonsList = async () => {
  const response = await axios.get(window.apiBaseUrl + "plan-pricing/buttons");
  return response.data.data;
};

const getPlanPricing = async () => {
  const response = await axios.get(window.apiBaseUrl + "plan-pricing", {
    headers: headers,
  });
  return response.data.data;
};

const getAddOnsPlan = async () => {
  const response = await axios.get(window.apiBaseUrl + "plan-pricing/addons", {
    headers: headers,
  });
  return response.data.data.list;
};

const getFaqList = async () => {
  const response = await axios.get(window.apiBaseUrl + "plan-pricing/faqs");
  return response.data.data.list;
};

const PlanAndPricing = () => {
  const {
    data: staticDetails,
    // error,
    isLoading: staticDetailsListLoading,
  } = useQuery("staticDetails", getStaticDetails, {
    cacheTime: 0, // Disable caching for this query
  });

  const { data: planPricing, isLoading: planPricingLoading } = useQuery(
    "planPricing",
    getPlanPricing,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  const { data: buttonsList, isLoading: buttonsListLoading } = useQuery(
    "buttonsList",
    getButtonsList,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  const { data: addOnsPlan, isLoading: addOnsPlanLoading } = useQuery(
    "addOnsPlan",
    getAddOnsPlan,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  const { data: faqList, isLoading: faqListLoading } = useQuery(
    "faqList",
    getFaqList,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  // if (error) return <div>An error occurred: {error.message}</div>;
  if (
    staticDetailsListLoading ||
    buttonsListLoading ||
    planPricingLoading ||
    addOnsPlanLoading ||
    faqListLoading
  )
    return <Loading />;

  return (
    <>
      <Helmet>
        <title>{staticDetails ? staticDetails.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={staticDetails ? staticDetails.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={staticDetails ? staticDetails.metaKeywords : SITETITLE}
        />
      </Helmet>
      <section className="MainComponent">
        <Container>
          <Row>
            <Col className="MaincomponentHead col-md-12 col-12 text-center">
              <h1 className="mt-5 text-white outer-headings d-flex justify-content-center align-items-center flex-wrap">
                {(staticDetails && staticDetails.planPricingHeading) || "N/A"}
                {/* <span>
                  <select className="plan-pricing-select  m-1 bg-transparent border-0 text-success fw-bold fs-1 mx-1 ">
                    <option className="fs-6" value="UniversityBusiness">
                      University
                    </option>
                    <option className="fs-6" value="CollegeBusiness">
                      College
                    </option>
                    <option className="fs-6" value="InstituteBusiness">
                      Institute
                    </option>
                    <option className="fs-6" value="SchoolBusiness">
                      School
                    </option>
                  </select>
                </span> */}
                <div className="inner-headings">
                  <span className="text-warning ms-2">
                    University <br />
                    College <br />
                    Institute <br />
                    School <br />
                  </span>
                </div>
              </h1>
            </Col>
          </Row>
          <Row>
            {buttonsList ? (
              <Col className="Col-md-12 d-flex flex-wrap align-items-center justify-content-center gap-2 mt-4 mb-5">
                {buttonsList.map((buttons, index) => (
                  <a
                    href={buttons.buttonLink}
                    key={index}
                    className="btn btn-primary btn-hover-success"
                  >
                    {buttons.buttonName}
                  </a>
                ))}
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Container>
        <Subcomponent
          staticDetails={staticDetails ? staticDetails : null}
          planPricing={planPricing ? planPricing : null}
          addOnsPlan={addOnsPlan ? addOnsPlan : null}
          faqList={faqList ? faqList : null}
        />
      </section>
    </>
  );
};

export default PlanAndPricing;
