import { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ERPLINK, RAZORPAYKEY, SITETITLE } from "../../../constants";
// import PropTypes from "prop-types";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

const Card = (props) => {
  const data = (props.data && props.data) || null;
  const plan = (props.plan && props.plan) || null;
  const activePlanDetails =
    (props.activePlanDetails && props.activePlanDetails) || null;

  const navigate = useNavigate();
  //currency calulation
  const countryDetails = (props.countryDetails && props.countryDetails) || null;

  const [currencySymbol, setCurrencySymbol] = useState("₹");
  useEffect(() => {
    if (!isEmpty(countryDetails)) {
      setCurrencySymbol(countryDetails.currencySymbol);
    }
  }, [countryDetails]);

  //currency calulation end

  const [Razorpay] = useRazorpay();

  // Check if the string exists within the component
  const isEnterpriseExists = localStorage.getItem("authUserType")
    ? localStorage.getItem("authUserType").includes("enterprise", "employee")
    : null;

  const generateOrderId = () => {
    const prefix = "CIQ"; // You can set a prefix for your order ID
    const timestamp = Date.now(); // Get the current timestamp
    // const random = Math.floor(Math.random() * 10000); // Generate a random number (between 0 and 9999)

    // Concatenate the prefix, timestamp, and random number to form the order ID
    const orderId = `${prefix}${timestamp}`;

    return orderId;
  };

  const orderId = generateOrderId();

  const handleButton = () => {
    alert(
      "Discover ERP plans for educational institutions and businesses. Not available for individual student or faculty purchase"
    );
  };

  const handleFreePlan = useCallback(async (items) => {
    const values = {
      planId: items.planId,
      planName: items.planName,
      planType: items.planType,
      planPriceType: plan,
      monthlyPrice: items.monthlyPrice,
      annuallyPrice: items.annuallyPrice,
      planKeyPoints: items.planKeyPoints.join(","),
      orderId: orderId,
    };

    await axios
      .post(window.apiBaseUrl + "auth/lms/razorpay/order", values, {
        headers: {
          AccessToken: "Bearer " + localStorage.getItem("authUser"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
          window.location.reload();
        } else {
          toast.warn(res.data.message, {
            autoClose: 3000,
            theme: "colored",
          });
          window.location.reload();
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  });

  const handleMakePayment = useCallback(
    (items, planPriceType) => {
      const payAmt =
        planPriceType === "year"
          ? items.annuallyPrice * 100 * 12
          : items.monthlyPrice * 100;

      const options = {
        key: RAZORPAYKEY,
        amount: parseFloat(payAmt.toFixed(2)),
        currency: "INR",
        name: SITETITLE,
        description:
          "Order ID " +
          orderId +
          "Confirm and proceed securely with our streamlined payment gateway.",
        image:
          "https://www.cosmosiq.ai/static/media/logo.a259b674c493e27b3baa.png",
        receipt: orderId,
        handler: async (res) => {
          if (res.razorpay_payment_id) {
            const values = {
              planId: items.planId,
              planName: items.planName,
              planType: items.planType,
              planPriceType: planPriceType,
              monthlyPrice: items.monthlyPrice,
              annuallyPrice: items.annuallyPrice,
              planKeyPoints: items.planKeyPoints.join(","),
              payAmount: payAmt,
              orderId: orderId,
              transactionId: res.razorpay_payment_id,
            };
            await axios
              .post(window.apiBaseUrl + "auth/lms/razorpay/order", values, {
                headers: {
                  AccessToken: "Bearer " + localStorage.getItem("authUser"),
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  toast.success(res.data.message, {
                    autoClose: 5000,
                    theme: "colored",
                  });
                  // window.location.reload();
                  navigate(
                    `/payment-successful?orderId=${res.data.purchasedData.orderId}`,
                    {
                      state: { data: res.data.purchasedData },
                    }
                  );
                } else {
                  toast.warn(res.data.message, {
                    autoClose: 3000,
                    theme: "colored",
                  });
                  // window.location.reload();
                }
              })
              .catch((err) => {
                // toast.error(err.response.data.message, {
                //   autoClose: 5000,
                //   theme: "colored",
                // });
              });
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "",
        },
        notes: {
          address: SITETITLE,
          order_id: orderId,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );

  return (
    <Row className="p-4 d-flex flex-wrap gap-3 justify-content-center">
      {data &&
        data.map((item, index) => (
          <Col
            sm={12}
            lg={3}
            xl={2}
            md={4}
            key={index + 1}
            style={
              item.planName !== "Professional"
                ? {
                    // width: "220px",
                  }
                : {
                    // width: "220px",
                    position: "relative",
                    top: "-15px",
                  }
            }
            className={`crd shadow p-3 mb-5 ${
              item.planName === "Professional" ? "bg-primary" : "bg-white"
            } rounded-4`}
          >
            {item.planName === "Professional" ? (
              <p
                className="bg-warning text-dark rounded-4 px-2"
                style={{ fontSize: "14px" }}
              >
                Most Popular
              </p>
            ) : (
              ""
            )}
            <div className="crd-head p-2">
              <h5
                className={`${
                  item.planName === "Professional"
                    ? "text-white"
                    : "text-primary"
                } my-2`}
              >
                {item.planName}
              </h5>
              <p
                className={`my-2 ${
                  item.planName === "Professional" ? "text-white" : ""
                }`}
              >
                {item.annuallyPrice <= 0 ? (
                  <strong>Free</strong>
                ) : (
                  <strong>
                    {currencySymbol}
                    {countryDetails && countryDetails.currencySymbol !== "₹" ? (
                      <>
                        {plan === "year"
                          ? (
                              parseInt(item.annuallyPrice) /
                              countryDetails.value
                            ).toFixed(2)
                          : (
                              parseInt(item.monthlyPrice) / countryDetails.value
                            ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {plan === "year"
                          ? item.annuallyPrice
                          : item.monthlyPrice}
                      </>
                    )}
                  </strong>
                )}
                <span style={{ fontSize: "12px" }}>/month</span> <br />
                {plan === "year" ? (
                  <span style={{ fontSize: "12px" }}>billed annually</span>
                ) : (
                  ""
                )}
              </p>
              {localStorage.getItem("authUserType") ? (
                <>
                  {isEnterpriseExists ? (
                    <>
                      {activePlanDetails &&
                      activePlanDetails.planName === item.planName &&
                      activePlanDetails.planPriceType === plan ? (
                        <Link className="btn btn-danger w-100">Active</Link>
                      ) : (
                        <Link
                          onClick={() =>
                            `${
                              item.planName === "Basic"
                                ? handleFreePlan(item)
                                : handleMakePayment(item, plan)
                            }`
                          }
                          className={`btn btn-primary btn-hover-success mt-2 mb-4 ${
                            item.planName === "Professional"
                              ? "btn-success"
                              : "btn-primary"
                          }`}
                        >
                          {item.buttonName}
                        </Link>
                      )}
                    </>
                  ) : (
                    <Link
                      onClick={handleButton}
                      className={`btn btn-primary btn-hover-success mt-2 mb-4 ${
                        item.planName === "Professional"
                          ? "btn-success"
                          : "btn-primary"
                      }`}
                    >
                      {item.buttonName}
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link
                    to={ERPLINK}
                    className={`btn btn-primary btn-hover-success mt-2 mb-4 ${
                      item.planName === "Professional"
                        ? "btn-success"
                        : "btn-primary"
                    }`}
                  >
                    {item.buttonName}
                  </Link>
                </>
              )}
            </div>
            <div className="crd-details p-2">
              <ul>
                {item.planKeyPoints.map((detail, index) => (
                  <li key={index + 1}>
                    <div className="d-flex gap-2">
                      <i
                        className={`ri-check-line ${
                          item.planName === "Professional"
                            ? "text-white"
                            : "text-primary"
                        }`}
                      ></i>
                      <span
                        className={`fs-13 fw-normal ${
                          item.planName === "Professional" ? "text-white" : ""
                        }`}
                      >
                        {detail}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        ))}
    </Row>
  );
};

// Card.propTypes = {
//   data: PropTypes.array.isRequired,
// };

export default Card;
