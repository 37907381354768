import React, { useEffect, useState } from "react";
import ThreeBoxLayout from "../../components/core/three-box-layout/ThreeBoxLayout";
import KeyFeatures from "../../components/core/key-features/KeyFeatures";
// import AboutUsTab from "../../components/page-wise-components/about-us/AboutUsTab";
import AboutUsInfoBlock from "../../components/page-wise-components/about-us/AboutUsInfoBlock";
import AboutUsCeoInfo from "../../components/page-wise-components/about-us/AboutUsCeoInfo";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import Loading from "../../components/global/Loading";
import HeaderBannerNew from "../../components/page-wise-components/about-us/HeaderBannerNew";
import YoutubeBanner from "../../components/page-wise-components/about-us/YoutubeBanner";
import Awards from "../../components/page-wise-components/about-us/Awards";
import News from "../../components/page-wise-components/about-us/News";
import GalleryGrid from "../../components/page-wise-components/about-us/GalleryGrid";
import TeamList from "../../components/page-wise-components/about-us/TeamList";

const getBanner = async () => {
  const response = await axios.get(window.apiBaseUrl + "about-us/banner");
  return response.data.data;
};

const getAboutInfoData = async () => {
  const response = await axios.get(window.apiBaseUrl + "about-us/about");
  return response.data.data;
};

const getStoryMissionData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "about-us/story-mission-vision"
  );
  return response.data.data;
};

const getWhyChooseUsData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "about-us/why-choose-us"
  );
  return response.data.data;
};

const getFounder = async () => {
  const response = await axios.get(window.apiBaseUrl + "about-us/founder");
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(window.apiBaseUrl + "about-us/meta-details");
  return response.data.data;
};

const AboutUs = () => {
  const {
    data: headerBannerData,
    // error,
    isLoading: aboutUsBannerLoading,
  } = useQuery("aboutUsBannerData", getBanner, { cacheTime: 0 });
  const { data: metaData } = useQuery("metaData", getMetaData, {
    cacheTime: 0,
  });
  const { data: aboutInfo, isLoading: aboutInfoLoading } = useQuery(
    "aboutInfo",
    getAboutInfoData,
    { cacheTime: 0 }
  );
  const { data: storyMission, isLoading: aboutStoryMissionLoading } = useQuery(
    "storyMission",
    getStoryMissionData,
    { cacheTime: 0 }
  );
  const { data: whyChooseUs, isLoading: aboutWhyChooseUsLoading } = useQuery(
    "getWhyChooseUsData",
    getWhyChooseUsData,
    { cacheTime: 0 }
  );
  const { data: founderData, isLoading: founderDataLoading } = useQuery(
    "getFounder",
    getFounder,
    { cacheTime: 0 }
  );

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const targetElement = document.getElementById("scrollTriggerId");

      if (targetElement) {
        const targetPosition = targetElement.offsetTop;
        const isStickyNow = scrollPosition >= targetPosition;

        setIsSticky(isStickyNow);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const stickyClass = isSticky
    ? "sticky-bar-comm sticky-bar-sticky"
    : "sticky-bar-comm";

  if (
    aboutUsBannerLoading ||
    aboutInfoLoading ||
    aboutStoryMissionLoading ||
    aboutWhyChooseUsLoading ||
    founderDataLoading
  )
    return <Loading />;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{metaData ? metaData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={metaData ? metaData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={metaData ? metaData.metaKeywords : SITETITLE}
        />
      </Helmet>
      {/* {headerBannerData ? (
        <HeaderBanner
          imgUrl={headerBannerData.bannerImage}
          subtitle={headerBannerData.bannerSubHeading}
          title={headerBannerData.bannerHeading}
          description={headerBannerData.bannerSubHeading}
          buttonsOne={headerBannerData.buttonNameOne}
          buttonsLinkOne={headerBannerData.buttonLinkOne}
          buttonsTwo={headerBannerData.buttonNameTwo}
          buttonsLinkTwo={headerBannerData.buttonLinkTwo}
        />
      ) : (
        ""
      )}
      <div className="main_newblock about-new-top ">
        <div className={stickyClass}>
          <div className="container">
            <ul>
              <li>
                <a href="#about-infos">About Cosmos IQ</a>
              </li>
              <li>
                <a href="#our-mission">We’ll get right to the point</a>
              </li>
              <li>
                <a href="#why-choose">Why choose us</a>
              </li>
              <li>
                <a href="#our-ceo">Meet Our CEO</a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <HeaderBannerNew />
      {aboutInfo ? (
        <AboutUsInfoBlock data={aboutInfo} customId="scrollTriggerId" />
      ) : (
        ""
      )}
      {storyMission ? (
        <ThreeBoxLayout
          customId="our-mission"
          backgroundImage="https://www.matriyeacademy.com/website-assets/images/mission-vision-story.png"
          pageTitle={storyMission.staticData.storyMissionVisionSectionHeading}
          pageSubTitle={
            storyMission.staticData.storyMissionVisionSectionSubHeading
          }
          boxContent={storyMission.sectionList}
        />
      ) : (
        ""
      )}
      <News />
      <Awards />

      <YoutubeBanner />

      {whyChooseUs ? (
        <KeyFeatures
          customId="why-choose"
          pageTitle={storyMission ? storyMission.staticData.metaHeadingTwo : ""}
          content={whyChooseUs}
          customClass="col-md-3"
        />
      ) : (
        ""
      )}

      {founderData ? <AboutUsCeoInfo data={founderData} /> : ""}
      <TeamList />
      <GalleryGrid />
    </>
  );
};

export default AboutUs;
