import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

const TranslateComponent = () => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const addGoogleTranslateScript = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);

      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "en,zh-CN,hi,es,fr,ar,bn,ru",
            layout:
              window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: false,
          },
          "google_translate_element"
        );
      };
    };

    if (!window.google || !window.google.translate) {
      addGoogleTranslateScript();
    } else {
      window.googleTranslateElementInit();
    }

    // Add CSS to hide Google Translate widget banner
    const style = document.createElement("style");
    style.innerHTML = `
      .VIpgJd-ZVi9od-ORHb-OEVmcd { display: none !important; }
      body { top: 0px !important; }
    `;
    document.head.appendChild(style);
  }, []);

  const toggleModal = () => setModal(!modal);

  const changeLanguage = (lang) => {
    // Delete cookies for www.xyz.com
    document.cookie =
      "googtrans=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=www.cosmosiq.ai";

    // Delete cookies for xyz.com
    document.cookie =
      "googtrans=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.cosmosiq.ai";

    document.cookie = `googtrans=/en/${lang}`;
    window.location.reload();
  };

  return (
    <>
      <li className="select-lang">
        <button
          className="link btn  px-2 d-flex align-items-center"
          onClick={toggleModal}
        >
          <i className="fa fa-globe text-dark fs-4" aria-hidden="true"></i>
        </button>
      </li>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal} className="notranslate">
          Select Language
        </ModalHeader>
        <ModalBody className="notranslate d-flex flex-wrap gap-2">
          <Button
            onClick={() => changeLanguage("en")}
            className="notranslate btn-light fw-normal fs-6"
          >
            English
          </Button>
          <Button
            onClick={() => changeLanguage("zh-CN")}
            className="notranslate btn-light fw-normal fs-6"
          >
            中文 (Mandarin)
          </Button>
          <Button
            onClick={() => changeLanguage("hi")}
            className="notranslate btn-light fw-normal fs-6"
          >
            हिन्दी (Hindi)
          </Button>
          <Button
            onClick={() => changeLanguage("es")}
            className="notranslate btn-light fw-normal fs-6"
          >
            Español (Spanish)
          </Button>
          <Button
            onClick={() => changeLanguage("fr")}
            className="notranslate btn-light fw-normal fs-6"
          >
            Français (French)
          </Button>
          <Button
            onClick={() => changeLanguage("ar")}
            className="notranslate btn-light fw-normal fs-6"
          >
            العربية (Modern Standard Arabic)
          </Button>
          <Button
            onClick={() => changeLanguage("bn")}
            className="notranslate btn-light fw-normal fs-6"
          >
            বাংলা (Bengali)
          </Button>
          <Button
            onClick={() => changeLanguage("ru")}
            className="notranslate btn-light fw-normal fs-6"
          >
            Русский (Russian)
          </Button>
          <Button
            onClick={() => changeLanguage("ar")}
            className="notranslate btn-light fw-normal fs-6"
          >
            العربية (Arabic)
          </Button>
        </ModalBody>
        <ModalFooter className="notranslate">
          <Button
            color="secondary"
            onClick={toggleModal}
            className="notranslate"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div id="google_translate_element" style={{ display: "none" }}></div>
    </>
  );
};

export default TranslateComponent;
